* {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

a {
  color: #4d4d4d;
  text-decoration: none;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F1F1F1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(47, 47, 47, .5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dvider_accordion {
  color: white !important;
  border-color: white !important;
}

.asd1234 .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.asd1234 .css-1bpp38c-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}

.asd1234 .css-k2w48s-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}

.asd1234 .css-t5ht0x-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.asd1234 .css-1vwfi6e-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.asd1234 .css-owb564-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.asd1234 .css-j83z29-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.asd1234 .css-dxlqns-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.asd1234 .css-pmvlxc-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.asd1234 .css-1vwfi6e-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.asd1234 .css-k2w48s-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded:hover {
  background-color: rgba(71, 70, 70, 0.6);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.asd1234 .asd123 {
  border-radius: 12px !important;
}

.asd1234 .layout-button-link {
  width: 100% !important;
  margin: 0px !important;
}

.asd1234 .layout-button {
  padding: 0;
  margin: 0;
  border-radius: 12px !important;
  width: 100% !important;
  min-width: 0;
  max-height: 46px;
}


.tanimlar-button {
  padding: 0 !important;
  border-radius: 12px !important;
  min-width: 0px !important;
}

.tanimlar-button .asd123 {
  text-transform: capitalize;
  width: 100% !important;
}

.islemler-button {
  padding: 0 !important;
  border-radius: 12px !important;
  min-width: 0px !important;
}

.islemler-button .asd123 {
  text-transform: capitalize;
  width: 100% !important;
}

.raporlar-button {
  padding: 0 !important;
  border-radius: 12px !important;
  min-width: 0px !important;
}

.raporlar-button .asd123 {
  text-transform: capitalize;
  width: 100% !important;
}

.harita-accordion {
  box-shadow: none !important;
  margin: 0 !important;
}

.harita-accordion.css-q9x3xp-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-radius: 10px;
}

.harita-personelarac .MuiTabs-indicator {
  background-color: #D05515;
}

.MuiTabs-flexContainer {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.dashboard-button .css-16yn0hh-MuiButtonBase-root-MuiButton-root {
  min-width: none;
  padding: 4px 6px;
}

.EZDrawer__container {
  background: transparent !important;
  box-shadow: none !important;
}

.atfatf {
  min-width: 337px !important;
}

.dashboard-logo {
  width: 100% !important;

}

.dashboard-logo-ic {
  max-width: 135px !important;
  max-height: 75px !important;
  width: 100% !important;
  height: 100% !important;
}

.vehicle-info2 {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }


}

@media screen and (max-width: 767px) {
  .vehicle-info {
    width: 100%;
  }
}